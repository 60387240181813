import React, { useState, useEffect } from 'react';
import CarnavalService from '../../api/backend/services/carnaval.service';
import SortUtil from '../../utils/sort.util';

const TableQuesitos = () => {
  const [carnival, setCarnival] = useState();

  async function load() {
    const response = await CarnavalService.list();
    const responsefiltred = response.find((carnaval) => carnaval.city === "São Paulo");
    responsefiltred.categoryItem = new SortUtil().executeSub(responsefiltred?.categoryItem, 'category', 'name');
    responsefiltred.categoryItem.forEach((item) => {
      item.judges = new SortUtil().execute(item.judges, 'name');
    });
    setCarnival(responsefiltred);
  }

  useEffect(() => {
    load().then();
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      load().then();
    }, 5000);

    return () => clearInterval(interval);
  }, [carnival]);
 
  function getNoteByJudgeAndSchool(judgeId, schoolId, categoryId) {
    if (!carnival || !carnival.notes) return;

    return carnival.notes.find(
      (note) =>
        note.judge === judgeId &&
        note.school === schoolId &&
        note.category === categoryId
    );
  }
  return carnival?.categoryItem?.map((categoryItem, index) => {
    return(
    <div key={index}>
      <div className="title-quesito-yellow m-g">
        <h1> Pontos por quesito - {categoryItem.category.name}</h1>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="panel">
            <div className="panel-body">
              <table className="table ">
                <thead>
                  <tr>
                    <th className="col-escolas">Escolas</th>
                    {categoryItem.judges.map((judge, index) => (
                      <th key={index}>{judge.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {carnival?.penalties.map((schoolItem, index) => (
                    <tr key={index}>
                      <td className="header-escolas">
                        {schoolItem.school.name}
                      </td>
                      {categoryItem.judges.map((judge, index) => {
                        return(
                        <td key={index}>
                          {getNoteByJudgeAndSchool(
                            judge._id,
                            schoolItem.school._id,
                            categoryItem.category._id
                          )?.value || 0}
                        </td>
                      )})}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )});
};

export default TableQuesitos;
