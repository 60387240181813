import SortUtil from '../../../utils/sort.util';
import {internalServerErrorMessage} from '../const/errors.const';
import {BACKEND_CARNIVAL_FIND_BY_CODE, BACKEND_CARNIVAL_FIND_ALL_EVENTS_INFOS} from '../../../routes/backend.routes';

class CarnavalService {
  static async list() {
    try {
      const response = await fetch(BACKEND_CARNIVAL_FIND_ALL_EVENTS_INFOS);
      const json = await response.json();
      return new SortUtil().execute(json, 'year', 'desc');
    } catch (error) {
      return new Error(internalServerErrorMessage);
    }
  }

  static async findByCode(code) {
    try {
      const response = await fetch(`${BACKEND_CARNIVAL_FIND_BY_CODE.replace(':id', code)}`,{cache: "no-cache"});
      const json = await response.json();
      
      json.penalties = new SortUtil().executeSub(json.penalties, 'school', 'name');
      json.categoryItem = new SortUtil().executeSub(json.categoryItem, 'category', 'name');
      json.categoryItem.forEach((item) => {item.judges = new SortUtil().execute(item.judges, 'name')});
      return json;
    } catch (error) {
      return new Error(internalServerErrorMessage);
    }
  }
}

export default CarnavalService;
