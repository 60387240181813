import React from 'react';
import { createRoot } from 'react-dom';
import { Helmet } from 'react-helmet';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));

root.render(
  <>
    <Helmet>
      {/* Adicione suas tags meta aqui */}
      <meta property="og:title" content="Apuração das escolas de samba do Grupo Especial de São Paulo - iG" />
      <meta property="og:description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial de São Paulo - Carnaval 2024." />
      <meta property="og:image" content="https://i0.statig.com.br/ig/carnaval-2022/iguinho-folia_720.png" />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:url" content="https://apuracao-carnaval.ig.com.br/sp" />
    </Helmet>
    <App />
  </>
);

reportWebVitals();
