import React from 'react';

const Footer = props => { 
    return (
        <div className="">

        </div>
        )
    }

export default Footer;
