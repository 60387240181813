class SortUtil {
  execute(arr, field, direction = 'asc') {
    return arr.sort((a, b) => {
      if (direction === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      }
      return a[field] < b[field] ? 1 : -1;
    });
  }

  executeSub(arr, field, subfield, direction = 'asc') {
    return arr.sort((a, b) => {
      if (direction === 'asc') {
        return a[field][subfield] > b[field][subfield] ? 1 : -1;
      }
      return a[field][subfield] < b[field][subfield] ? 1 : -1;
    });
  }
}

export default SortUtil;
