import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';

import Header from '../components/sp/Header';
import ClassificacaoCompleta from '../components/sp/ClassificacaoCompleta';
import TableQuesitos from '../components/sp/TableQuesitos';
import Footer from '../components/sp/Footer';

function PageCarnavalSp() {
  useEffect(() => {
    document.title = 'iG na Folia | Carnaval';
  
    // Adicionando a tag meta 'og:title'
    var newmetaTitle = document.createElement('meta');
    newmetaTitle.setAttribute('property', 'og:title');
    newmetaTitle.content = 'Apuração das escolas de samba do Grupo Especial de São Paulo - iG';
    document.getElementsByTagName('head')[0].appendChild(newmetaTitle);
  
    // Adicionando a tag meta 'og:description'
    var newmetaDescription = document.createElement('meta');
    newmetaDescription.setAttribute('property', 'og:description');
    newmetaDescription.content = 'Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial de São Paulo - Carnaval 2024.';
    document.getElementsByTagName('head')[0].appendChild(newmetaDescription);
  
    // Adicionando a tag meta 'og:image'
    var newmetaImage = document.createElement('meta');
    newmetaImage.setAttribute('property', 'og:image');
    newmetaImage.content = 'https://i0.statig.com.br/ig/carnaval-2022/iguinho-folia_720.png';
    document.getElementsByTagName('head')[0].appendChild(newmetaImage);
  
    // Adicionando a tag meta 'og:locale'
    var newmetaLocale = document.createElement('meta');
    newmetaLocale.setAttribute('property', 'og:locale');
    newmetaLocale.content = 'pt_BR';
    document.getElementsByTagName('head')[0].appendChild(newmetaLocale);
  
    // Adicionando a tag meta 'og:url'
    var newmetaUrl = document.createElement('meta');
    newmetaUrl.setAttribute('property', 'og:url');
    newmetaUrl.content = 'https://apuracao-carnaval.ig.com.br/sp';
    document.getElementsByTagName('head')[0].appendChild(newmetaUrl);
  
    // Atualizando o favicon
    document.getElementById('favicon').href =
      'https://i0.statig.com.br/favicon/favicon.ico';
  }, []);
  

  return (
    <div className="App">
      <Header />
      <div className="container ig">
        <div className="row">
          <div className="col-12 col-md-12">
            <ClassificacaoCompleta />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <TableQuesitos></TableQuesitos>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <Footer />
          </div>
        </div>
      </div>
      <Helmet>

        <meta property="og:title" content="Apuração das escolas de samba do Grupo Especial de São Paulo - iG" />
        <meta property="og:description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial de São Paulo - Carnaval 2024." />
        <meta property="og:image" content="https://i0.statig.com.br/ig/carnaval-2022/iguinho-folia_720.png" />

        <meta name="og:title" content="Apuração das escolas de samba do Grupo Especial de São Paulo - iG" />
        <meta name="og:description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial de São Paulo - Carnaval 2024." />
        <meta name="og:image" content="https://i0.statig.com.br/ig/carnaval-2022/iguinho-folia_720.png" />  
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@iG" />
        <meta name="twitter:title" content="Apuração das escolas de samba do Grupo Especial de São Paulo - iG "/>
        <meta name="twitter:description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial de São Paulo - Carnaval 2024"/>
        <meta name="twitter:url" content="https://apuracao-carnaval.ig.com.br/sp"/>
        <meta name="twitter:image:src" content="https://i0.statig.com.br/ig/carnaval-2022/iguinho-folia_720.png"/>
        <meta name="description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial de São Paulo - Carnaval 2024."/>
        <meta name="keywords" content="folia, jornalismo, notícias, carnaval, São Paulo, blocos, escola de samba, apuração, grupo especial, grupo de acesso"/>
        
      </Helmet>
    </div>
  );
}

export default PageCarnavalSp;
