import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import CarnavalService from "../../api/backend/services/carnaval.service";
import SortUtil from "../../utils/sort.util";

const ClassificacaoCompleta = () => {
  const [carnival, setCarnival] = useState();
  const [winner, setWinner] = useState("");
  const [carnivalList, setCarnivalList] = useState([]);

  const returnSum = useCallback((notes, field) => {
    notes.map((note) => (note[field] = calculateSum(note[field])));
    return notes;
  }, []);

  const calculateApuration = useCallback(
    (carnivalArg) => {
      const result = carnivalArg.categoryItem.map((item) => {
        const schoolCategoryNotes = carnivalArg.penalties.map((schoolItem) => {
          const schoolNotes = carnivalArg.notes.filter((note) => {
            return (
              note.school === schoolItem.school._id &&
              note.category === item.category._id
            );
          });
          return {
            [item.category.name]: schoolNotes.map((note) => note.value),
            school: schoolItem.school.name,
          };
        });
        const resultDiscardMinAndMax = discardMinAndMax(
          carnivalArg,
          schoolCategoryNotes,
          item.category.name
        );

        return returnSum(resultDiscardMinAndMax, item.category.name);
      });

      const resultFinal = carnivalArg.penalties.map((schoolItem) => {
        const schoolNote = { name: schoolItem.school.name };
        const notes = result.map((categoryNote) => {
          const note = categoryNote.find(
            (category) => category.school === schoolItem.school.name
          );
          return note;
        });
        const notesTotal = notes
          .map((note) => note[Object.keys(note)[0]])
          .reduce((acc, curr) => Math.abs(acc) + Math.abs(curr), 0);
        return {
          ...schoolNote,
          value: Math.abs(notesTotal) - Math.abs(schoolItem.value),
        };
      });
      return new SortUtil().execute(resultFinal, "value", "desc");
    },
    [returnSum]
  );

  const load = useCallback(async () => {
    const response = await CarnavalService.list();
    const responsefiltred = response.find((carnaval) => carnaval.city === "São Paulo");
    const carnivalOrder = calculateApuration(responsefiltred);
    
    setCarnival(responsefiltred);
    setCarnivalList(carnivalOrder);

    setWinner(carnivalOrder[0].name);
  }, [calculateApuration]);

  function discardMinAndMax(carnivalArg, notes, field) {
    const categoryItem = carnivalArg.categoryItem.find((item) => item.category.name === field);

    notes = notes.map((note) => {
      if (categoryItem.judges.length !== note[field].length) {
        for (let i = 0; i < categoryItem.judges.length - note[field].length; i++) {
          note[field].push("0.0");
        }
      }
      return note
    });

    notes.map((note) => (note[field] = note[field].sort((a, b) => a - b)));
    if (carnivalArg.discard_min) {
      notes.map((note) => note[field].shift());
    }

    if (carnivalArg.discard_max) {
      notes.map((note) => note[field].pop());
    }

    return notes;
  }

  function calculateSum(myArray) {
    let summ = 0;
    for (let i = 0; i < myArray.length; i++) {
      if (isNaN(myArray[i])) continue;
      summ = Math.abs(summ) + Math.abs(myArray[i]);
    }
    let notesCount = 1;
    if (myArray.length > 1) {
      notesCount = Number(myArray.length);
    }

    return Number(Math.abs(summ).toFixed(1));
  }

  useEffect(() => {
    load().then();
  }, [load]);

  useEffect(() => {}, [carnivalList]);

  useEffect(() => {
    const interval = setInterval(async () => {
      load().then();
    }, 5000);

    return () => clearInterval(interval);
  }, [carnivalList, load]);

  return (
    <>
      <div className="title-carnaval ajuste-title-sp">
        Carnaval de São Paulo
      </div>

      <div className="centralizar">
        {carnival?.finished && (
          <div className="escola-campea">
            <img
              className="img-campea"
              src="https://i0.statig.com.br/ig/images/banner.jpg"
              alt=""
            />
            <div className="titulo-escola-campea">
              {carnival?.winner ? carnival.winner : winner}
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-lg-5 ig-c">
            <div>
              <img
                className="ig-picture"
                src="https://i0.statig.com.br/ig/images/v1.jpg"
                alt=""
              />
            </div>
          </div>

          <div className="col-lg-7">
            <div className="title-quesito-completo m-g">
              <h1> Classificação Completa</h1>
            </div>
            <div className="panel">
              <div className="panel-body table-responsive">
                <table className="table ">
                  <thead>
                    <tr>
                      <th className="col-escolas">Pos</th>
                      <th className="col-escolas">Escolas</th>
                      <th className="col-escolas">Pontuação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {carnivalList.map((carnival, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{carnival.name}</td>
                        <td>
                          {parseFloat(carnival.value).toFixed(2).toString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassificacaoCompleta;
