import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// rota de componentes
import PageCarnavalRj from '../pages/CarnavalRj';
import PageCarnavalSp from '../pages/CarnavalSp';

function Rotas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/rj" element={<PageCarnavalRj />} />
        <Route exact path="/sp" element={<PageCarnavalSp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Rotas;
