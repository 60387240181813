import React from "react";
import "../App.css";
import { Helmet } from "react-helmet";

import HeaderRj from "../components/rj/HeaderRj";
import ClassificacaoCompletaRj from "../components/rj/ClassificacaoCompletaRj";
import TableQuesitosRj from "../components/rj/TableQuesitosRj";

function PageCarnavalRj() {
  return (
    <div className="App">
      <HeaderRj />
      <div className="container in">
        <div className="row">
          <div className="col-12 col-md-12">
            <ClassificacaoCompletaRj />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <TableQuesitosRj></TableQuesitosRj>
          </div>
        </div>
      </div>

      <Helmet>
        <meta
          property="og:site_name"
          content="Apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Jornal O Dia"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:url" content="https://apuracao.odianafolia.com.br" />
        <meta
          property="og:title"
          content="Apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Jornal O Dia"
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo-odianafolia.png" />
        <meta
          property="og:description"
          content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Carnaval 2022."
        />
        <meta
          name="description"
          content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Carnaval 2022."
        />
        <meta
          name="keywords"
          content="folia, jornal o dia, jornalismo, notícias, carnaval, Rio de Janeiro, blocos, escola de samba, o dia na folia, apuração, grupo especial, grupo de acesso"
        />
      </Helmet>
    </div>
  );
}

export default PageCarnavalRj;
